import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.sass']
})
export class SidenavComponent implements OnInit {
  @ViewChild('sidenav') sideNav?: MatSidenav;
  @Output() openSideNav = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  toggleSideNav(){
    this.openSideNav.emit(true);
  }

}
