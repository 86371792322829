import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { ProfileContentComponent } from './modules/shared/layout/main-view/profile-content/profile-content.component';
import { ParallaxFxDirective } from './directives/parallax-fx.directive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AboutSectionComponent } from './modules/shared/layout/main-view/profile-content/about-section/about-section/about-section.component';
import { MatCardModule } from '@angular/material/card';
import { ProjectsComponent } from './modules/shared/layout/main-view/profile-content/projects-section/projects.component';
import { HeaderComponent } from './components/header/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutModule } from '@angular/cdk/layout';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryModule } from 'ng-gallery';
// import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SkillsExpSectionComponent } from './modules/shared/layout/main-view/profile-content/skills-exp-section/skills-exp-section/skills-exp-section.component';
import { DownloadService } from './modules/shared/layout/main-view/profile-content/skills-exp-section/skills-exp-section/services/download.service';
import { HttpClientModule } from '@angular/common/http';

import { ContactFormComponent } from './modules/shared/layout/main-view/profile-content/components/contact-form/contact-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    SpinnerComponent,
    ContactFormComponent,
    ProfileContentComponent,
    SkillsExpSectionComponent,
    AboutSectionComponent,
    ProjectsComponent,
    GalleryComponent,
    ParallaxFxDirective,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //SharedModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatSidenavModule,
    MatDividerModule,
    GalleryModule,
    // ImageViewerModule,
    RouterModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatDialogModule,
    MatProgressBarModule
    
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
