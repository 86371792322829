
<section>
    <mat-drawer-container>
        <mat-drawer-content>
            <div>
                <gallery id="projectGallery" [itemTemplate]="itemTemplate" (itemClick)="openGalleryLink($event)"></gallery>

                <!-- Add custom template to image items -->
                <ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
                    <span style="cursor: pointer;" *ngIf="type === 'image' && index === currIndex" class="item-text" >
                        {{data?.title}}
                    </span>
                </ng-template>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</section>