import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

@Component({
  selector: 'eo-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.sass']
})
export class ProfileContentComponent implements OnInit {
  isLoading: boolean = true;
  details: any;
 

  constructor(public dialog: MatDialog) { }

  ngOnInit() {}
  
  public downloadCV(){
    const pdfUrl = './assets/files/Enoch_Offiah_Resume.pdf';
    const pdfName = 'Enoch_Offiah_CV';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  openContactForm(): void{
    // open mat dialog with form 
    const dialogRef = this.dialog.open(ContactFormComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  
}
