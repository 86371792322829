<app-header (openSideNav)="toggleSideNav();" (closeSideNav)="closeSideNav();"></app-header>
<!-- <div class="wrapper"> -->
    <mat-sidenav-container>
        <mat-sidenav style="height: calc(100% - 98px);" #sidenav class="sidebar mat-elevation-z6" position="start" style="position: fixed;">
            <app-sidenav (closeSideNav)="toggleSideNav();"></app-sidenav>
        </mat-sidenav>
    
        <div style="min-height: 100%;">
            <eo-profile-content></eo-profile-content>
        </div>
    </mat-sidenav-container>
<!-- </div> -->
