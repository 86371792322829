<div style="margin: 10px;
display: flex;
flex-direction: column;">
  <!-- todo add click function to scroll to each section on page -->
  <button class="sidenav-button" mat-flat-button>
    <span class="material-icons">
      <mat-icon style="font-size: 22px; margin-left: 5px;">account_circle</mat-icon>
    </span>
    Background</button>
  <div style="margin-top: 4px;">
    <button class="sidenav-button" mat-flat-button>
      <span class="material-icons" style="margin-right: 5px;">
        <mat-icon style="font-size: 22px; margin-left: 5px;">source</mat-icon>
      </span>Projects</button>
  </div>
  <div style="margin-top: 4px;">
    <button class="sidenav-button" mat-flat-button>
      <span class="material-icons" style="margin-right: 5px;">
        <mat-icon style="font-size: 22px; margin-left: 5px;">work_history</mat-icon>
      </span>Experience</button>
  </div>
    <!-- <mat-divider></mat-divider> -->
</div>